.welcome__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 350px;
    margin-bottom: 40px;
  }

  h1 {
    margin-top: 30px;
    text-align: center;
    font-size: 34px;
  }
}

.welcome__p {
  margin-top: 30px;
  max-width: 580px;
  text-align: justify;
  text-justify: inter-word;
}

.required {
  color: red;
}

.login-page-checkbox-label__wrapper {
  display: flex;
}
