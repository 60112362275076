.exhibitor {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  margin: 20px 0;
  justify-content: center;

  &__logo {
    flex: 1.5;
    border-bottom: 1px solid $white-light;
    img {
      max-width: 100px;
      max-height: 50px;
    }
  }

  &__text {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-size: 14px;
      font-weight: 600;
      color: $white;
    }
  }

  &--xiaomi {
    background-color: $xiaomi;
  }
  &--spotify {
    background-color: $spotify;
  }
  &--intel {
    background-color: $intel;
  }
  &--tesla {
    background-color: $tesla;
  }
}

.exhibitor-description {
  display: flex;
  flex-direction: column;
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $grey-light;
    border-radius: 10px;
    width: 35px;
    height: 35px;
    border: none;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 1em 0;

    img {
      max-width: 100px;
      max-height: 100px;
    }
  }

  &__content {
    img {
      width: auto !important;
      max-height: 328px;
    }
  }

  p {
    font-size: 15px;
    color: $text-grey;
  }
}

.exhibitor-stand-buttons {
  display: flex;
  justify-content: flex-end;
}

.exhibitor-map {
  min-height: 700px;
  background-color: $white;
  margin: 1em 0 0 0;
  z-index: 0;
  &__show-all {
    height: 45px;
    border-radius: 5px;
    border: 1px solid $text-grey2;
    background-color: $white;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    &:hover {
      background-color: $xiaomi;
      color: $white;
    }
    @media (max-width: $tabletSmall) {
      font-size: 16px;
    }
  }
}

.exhibitor-bar-detailed-title {
  color: $white;
  margin: 1em 0;
  &:nth-of-type(2) {
    margin: 3em 0 1em 0;
  }
}

.exhibitor-bar-detailed {
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  min-height: 80px;
  margin: 5px 0;
  color: $black;
  width: 100%;
  gap: 10px;
  background: linear-gradient(to right, $xiaomi 5px, $white 5px);
  box-shadow: 0px 2px 3px #0000001a;
  cursor: pointer;
  border: none;
  &__logo {
    height: auto;
    width: 80px;
    // border: 1px solid $grey-light;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    img {
      width: 100%;
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    span {
      color: $black;
    }
  }
  &__upper-part {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  &__heart {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $grey-light;
    border-radius: 5px;
    border: none;
  }
  &__text {
    display: flex;
    flex: 1;
    flex-direction: column;
    text-align: left;

    span:nth-child(2) {
      font-weight: normal;
      color: $text-grey;
    }
  }
  hr {
    width: 100%;
    color: $grey-light;
    border-color: $grey-light;
  }
  p {
    color: $text-grey;
    button {
      color: $xiaomi;
      border: none;
      background-color: transparent;
    }
  }
}
